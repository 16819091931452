import React, { useState } from 'react';
import OverlaySidebarLayout from '../../components/square/OverlaySidebarLayout';
import Market from '../../components/main/market/Market';
import MarketFilter3 from '../../components/main/filter/MarketFilter3';
// import MarketFilter2 from '../../components/main/filter/MarketFilter2';
// import AdaptiveSidebarLayout from '../../components/square/AdaptiveSidebarLayout';

// import FeaturesCarousel from '../../components/main/carousel/FeaturesCarousel';
import SEO from '../../components/SEO/SEO';
// import NewLook from '../../components/intro/video/NewLook';
// import Modal from '../../components/listing/Modal';

const MarketPage = () => {
  // const introViewed = localStorage.getItem('introViewed');

  // const [isFilterOpen, setIsFilterOpen] = useState(window.innerWidth >= 768);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <>
      <SEO
        title="Buy & Sell With Ease - Deehiy"
        description="Buy and sell property with ease globally and take control of your real estate business"
        name="Deehiy"
        type="home page"
        image={`${process.env.REACT_APP_BASE_URL_PROD}/images/meta-image.jpeg`}
      />
      {/* intro modal */}
      {/* {introViewed !== process.env.REACT_APP_VIDEO_INTRO_ID && (
        <Modal absoluteCloseBtn={true}>
          <NewLook />
        </Modal>
      )} */}

      {/* <AdaptiveSidebarLayout Filter={MarketFilter2}>
        <Market />
      </AdaptiveSidebarLayout> */}
      <OverlaySidebarLayout
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        Filter={MarketFilter3}
        isFullWidth={true}
        isMarket={true}
      >
        <Market />
      </OverlaySidebarLayout>
    </>
  );
};

export default MarketPage;
